<template>
    <section class="content">
        <month-picker-input @change="filterBulan" v-model="filterBulanValue" :no-default="true" placeholder="Pilih Bulan"></month-picker-input>
        <table class="table table-hover" ref="tblagendaharian">
            <thead>
                <tr>
                    <th>HARI</th>
                    <th>TANGGAL</th>
                    <th>STAFF</th>
                    <th>AGENDA</th>
                    <th>RENCANA</th>
                    <th>KETERANGAN</th>
                    <th>HASIL</th>
                    <!-- <th>IN</th>
                    <th>OUT</th> -->
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <input type="text" class="form-control" ref="daterange" id="daterange" autocomplete="off" style="display:none">
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                              <label class="control-label">STAFF</label>
                                <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal</label>
                                <input type="hidden" name="row_id" v-model="form.id"/>
                                <datepicker
                                  placeholder="Tanggal"
                                  v-model="form.tanggal"
                                  :change="onChange()"
                                  required
                                />
                            </div>
                            <div class="col-md-5 form-group">
                                <label class="control-label">Hari</label>
                                <input id="hari" class="form-control hari" v-model="form.hari" type="text" name="hari" readonly />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Agenda Kegiatan</label>
                                <input id="agenda" class="form-control agenda" v-model="form.agenda" type="text" required="required" name="agenda" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Keterangan</label>
                                <textarea class="form-control" v-model="form.keterangan"></textarea>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Rencana</label>
                                <textarea class="form-control" v-model="form.rencana"></textarea>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Hasil</label>
                                <textarea class="form-control" v-model="form.hasil"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { auth, createTable, authFetch } from "@/libs/hxcore";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment";
import datepicker from "@/components/Datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";
import { MonthPickerInput } from 'vue-month-picker'

export default {
  name: "AgendaHarianAllStaff",
  components: {
    vSelect,
    datepicker,
    MonthPickerInput
  },
  data() {
    return {
      errors: [],
      staffPic: [],
      optStaff: [],
      method: "",
      roles: "",
      formTitle: "Tambah",
      filterBulanValue: '',
      form: {
        tanggal: moment().format("DD/MM/YYYY"),
        agenda: "",
        keterangan: "",
        rencana: "",
        hasil: "",
        hari: "",
        staff_id: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    // this.loadStaff();
    authFetch('/management/agenda_harian/staff')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
            this.optStaff = js.data;
    })
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    filterBulan (date) {
      this.filterBulanValue = moment(date.from).format("YYYY-MM");
      this.table.api().ajax.reload();
    },
    // loadStaff() {
    //   authFetch("/pr/proposal_lpj_kegiatan/staff").then((res) => {
    //     res.json().then((json) => {
    //       //get default address
    //       if (json.success) {
    //         this.staffPic.push({
    //           id: json.data.staff_pic,
    //           nama_staff: json.data.nama_staff,
    //         });
    //       }
    //     });
    //   });
    // },
    // onSearch(search, loading) {
    //   if (search.length > 3) {
    //     loading(true);
    //     var vm = this;
    //     authFetch(
    //       `/pr/proposal_lpj_kegiatan/cari_staff?q=${escape(search)}`
    //     ).then((res) => {
    //       res.json().then((json) => (vm.staffPic = json.items));
    //       loading(false);
    //     });
    //   }
    // },
    onChange: function () {
      var namahari = "Minggu Senin Selasa Rabu Kamis Jumat Sabtu";
      namahari = namahari.split(" ");
      if (this.form.tanggal) {
        var split = this.form.tanggal.split("/");
        var tgl = new Date(split[2], split[1] - 1, split[0]);
        var hari = tgl.getDay();

        this.form.hari = namahari[hari];
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/management/agenda_harian";
      if (this.method == "PUT")
        urlSubmit = "/management/agenda_harian/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tblagendaharian, {
      title: "List Agenda Harian",
      roles: this.$route.params.roles,
      ajax: "/management/agenda_harian",
      columns: [
        { data: "hari"},
        { data: "tanggal" },
        { data: "staff" },
        { data: "agenda" },
        { data: "rencana" },
        { data: "keterangan" },
        { data: "hasil" }
      ],
      // filter:false,
      filterBy: [0, 2, 3],
      rowCallback: function (row, data) {
        // var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
        // namahari = namahari.split(" ");
        // var tgl = new Date(data.tgl_day);
        // var hari = tgl.getDay();
        // $('td:eq(0)', row).html(namahari[hari]);
      },
      paramData: function (d) {
        // d.mapel = $('.mapel').val();
        // d.kelas = $('.kelas').val();
        // d.akademik = $('.akademik').val();
        // d.tentor = $('.tentor').val();
        d.filterBulanValue = self.filterBulanValue;
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Agenda Harian";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Agenda Harian";
          $(e.formDialog).modal("show");
          // $('#daterange').daterangepicker({ startDate: moment(evt.data.tanggal_mulai), endDate: moment(evt.data.tanggal_selesai) });
          // if ($(e.formDialog).modal('show')) {
          //     var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
          //     namahari = namahari.split(" ");
          //     if (this.form.tanggal_mulai) {
          //         var split = this.form.tanggal_mulai.split('/')
          //         var tgl = new Date(split[2],split[1]-1,split[0]);
          //         var hari = tgl.getDay();
          //         this.form.hari = namahari[hari];
          //     }
          // }
        } else if (evt.role == "delete" && evt.data) {
          self.form = evt.data;
          Swal.fire({
              title: "Hapus data?",
              icon: "question",
              denyButtonText: '<i class="fa fa-times"></i> Hapus',
              showCancelButton: true,
              showDenyButton: true,
              showConfirmButton: false,
          }).then((result) => {
              if (result.isDenied) {
              authFetch("/management/agenda_harian/" + evt.data.id, {
                  method: "DELETE",
                  body: "id=" + evt.data.id,
              })
                  .then((res) => {
                  return res.json();
                  })
                  .then((js) => {
                  this.table.api().ajax.reload();
                  });
              }
          });
        }
      },
    });
    // new Daterangepicker(this.$refs.daterange, {
    // }, function(d1, d2) {
    //     self.form.start = d1.format('DD/MM/YYYY')
    //     self.form.end = d2.format('DD/MM/YYYY')
    // });
  },
};
</script>
<style type="text/css">
  .month-picker {
    z-index: 21;
    position: relative;
  }
  .month-picker__year {
    position: relative;
    z-index: 21;
  }
  .month-picker-input-container{
    margin: 0px auto;
    margin-bottom: 12px;
  }
  .month-picker-input {
    text-align: center;
  }
</style>